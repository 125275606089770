<template>
    <div class="fotter">
        <div class="container">
            <div>
                <div class="logo"><img src="@/assets/images/logo1.png" /></div>
                <span>{{ beianInfo.company }}</span>
            </div>
            <div>公司地址：{{ beianInfo.address }}</div>
            <div v-if="beianInfo.tel">联系电话：{{ beianInfo.tel }}</div>
        </div>
        <div class="beian">
            <a target="_blank" href="https://beian.miit.gov.cn/">{{ beianInfo.icp }}</a>
            <a v-if="beianInfo.tel">投诉电话：{{ beianInfo.tel }}</a>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['beianList']) },
    data() {
        return {
            beianInfo: null,
        }
    },
    created() {
        this.beianInfo = this.beianList[0]
    },
    mounted() {
        this.beianList.forEach((item) => {
            if (location.host.indexOf(item.host) > -1) {
                this.beianInfo = item
            }
        })
    },
}
</script>
<style lang="scss" scoped>
.fotter {
    flex-shrink: 0;
    padding: 20px 0;
    width: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: $center-width;
    .container {
        width: $center-width;
        display: flex;
        justify-content: space-between;
        > div {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 16px;
            margin: 0 20px;
        }
        .logo {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            img {
                width: 32px;
                height: 32px;
                background: none;
            }
        }
    }
    .beian {
        color: #ffffff;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        align-items: center;
        a {
            margin: 0 18px;
            color: #ffffff;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        a:hover {
            opacity: 0.4;
        }
    }
}

@media screen and (max-width: $center-width) and (min-width: 640px) {
    .fotter {
        min-width: auto;
        .container {
            width: auto;
            > div {
                font-size: 14px;
            }
            .logo {
                width: 42px;
                height: 42px;
                margin-right: 8px;
                img {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .fotter {
        padding: 12px 0;
        min-width: auto;
        .container {
            width: auto;
            display: block;
            > div {
                margin: 12px;
                font-size: 14px;
            }
            .logo {
                width: 42px;
                height: 42px;
                margin-right: 8px;
                img {
                    width: 28px;
                    height: 28px;
                }
            }
        }
        .beian {
            display: block;
            a {
                margin: 12px;
            }
        }
    }
}
</style>
